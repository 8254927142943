.ant-input {
    border-color: #b9b9c3;
}

.ant-select:not(.ant-select-status-error) {
    .ant-select-selector {
        border-color: #b9b9c3 !important;
    }
}

.ant-input-affix-wrapper {
    border-color: #b9b9c3;
}

.ant-input-wrapper {
    .ant-input-group-addon {
        .ant-btn {
            border-color: #b9b9c3;
        }
    }
}

textarea {
    border-color: #b9b9c3;
}

.ant-picker {
    border-color: #b9b9c3;
}

.ant-radio-group {
    &.ant-radio-group-small {
        border-radius: 8px;
        border: 1px solid #e6e6eb;
        background-color: #fff;

        .ant-radio-button-wrapper {
            margin: -1px;
            text-align: center;
            border-inline-start-width: 1px;

            &:hover {
                color: #1E1D1D;
            }

            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-right-color: transparent;
            }

            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &:not(:first-child) {
                border-left-color: transparent;
            }

            &:has(.ant-radio-button-checked) {
                border-radius: 8px;
                border-color: #1E1D1D;
                color: #1E1D1D;
            }

            &::before {
                width: 0;
            }
        }
    }
}
