@import './assets/plugins/bootstrap/css/bootstrap.min.css';

@import './assets/css/main.css';

/* @import './assets/css/theme1.css'; */

@import './assets/css/custom.css';

@import './assets/plugins/charts-c3/c3.min.css';

@import './assets/plugins/summernote/dist/summernote.css';

@import './assets/plugins/jvectormap/jvectormap-2.0.3.css';

@import './assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css';

@import './assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css';

@import './assets/plugins/fullcalendar/fullcalendar.min.css';

@import './assets/plugins/jquery-steps/jquery.steps.css';

@import '@/pages/authenticated/Messages/message.module.scss';

@import '@/pages/authenticated/AuthoriseExpenses/authoriseExpenses.module.scss';

@import '@/pages/authentication/Login/login.module.scss';

@import '@/components/Shared/layout.module.scss';

@import '@/pages/authenticated/NewHires/newHire.module.scss';

@import '@/pages/authenticated/Invoices/invoice.module.scss';

@import '@/pages/authenticated/Permissions/permission.module.scss';

@import '@/assets/plugins/animate-css/animate.min.css';

@import '@/assets/plugins/animate-css/vivify.min.css';

@import '@/common/styles/antd.module.scss';

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-thumb {
  background: #000000 !important;
  border-radius: 5px;
}

.ck.ck-content {
  height: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto !important;
}

.small-text {
  font-size: 14px;
}

.notification-highlight {
  background-color: rgba(91, 106, 162, 0.2);
}
.activity-type {
  color: #1e1d1d;
  font-size: 600;
}

.ant-upload.ant-upload-drag {
  border: 2px dashed #d3d1cf !important;
  &:hover {
    border: 2px dashed black !important;
  }
}

.border-primary {
  border-color: #225EE5 !important;
}

.rounded-right {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.object-fit-cover {
  object-fit: cover;
}
