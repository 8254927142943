
#left-sidebar {
    background-color: #ffffff;
    width: 272px;
    overflow: unset;

    .logo-container {
        height: 80px;
        width: 100%;
    }

    .logo-wrapper {
        .logo-container {
            height: 80px;
            width: 100%;
            background-image: url('/src/assets/images/logo-eor.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .sidebar-nav {
        overflow: hidden auto;
        padding-bottom: 20px;
        height: calc(100vh - 20px);
    }

    .left-sidebar-close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu-list {
        background-image: #ffffff;
        color: #a7b1c2;
        font-weight: 700;
    }

    .menu-list-container {
        position: relative;
        .logo-wrapper {
            position: relative;
            top: 0;
            left: 0;
            width: 272px;
            background-color: white;
            z-index: 1000;
        }

        .logo-wrapper-bottom {
            position: absolute;
            top: 80px;
            left: 0;
            width: 272px;
            background-color: white;
            z-index: 1000;

            img{
                object-fit: cover;
            }
        }

        .ant-menu.has-client-logo {
            top: 80px;
        }
    }

    .menu-list-mini-container {
        height: 100%;
        width: 50px;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;

        .logo-container {
            height: 34px;
        }
        
        .menu-list-mini{
            /* background-image: linear-gradient(90deg, #4f6cb2 0%, #41589c 50%, #2a3275 100%); */
            height: 100%;
            color: #a7b1c2;
            font-weight: 700;
            
            & > li {
                padding: 12px !important;
            }

        }
    }

    .fold-menu-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        font-size: 20px;
    }

    .version {
        color:#a7b1c2;
        font-size: 10px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }

    .ant-menu-light.ant-menu-inline .ant-menu-item::after {
        border-inline-end: 0;
    }
}

#logo-header-wrapper {
    width: 272px;

    .logo-container {
        height: 80;
        width: 100%;
    }

    .logo-wrapper {
        .logo-container {
            height: 80px;
            width: 100%;
            background-image: url('/src/assets/images/logo-eor.svg');
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.page.page-no-side-bar{
    width: 100%;
    left: 0;
}

.page {
    transition: all 0.3s ease-in-out;
    left: 272px;
    width: calc(100% - 272px);
    height: 100vh;
    background-color: white;

    #page_top {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 100;

        .page-header {
            padding: 0;
        }
    }

    .container-fluid {
        background-color: #F7F6F4;
        height: calc(100% - 70px);

        .card-header{
            padding: 10px 20px;
        }

        .card-body{
            padding:0;
        }

        &.no-height {
            height: unset;
        }
    }

    .page-listing-title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: #1E1D1D;
    }

    .page-listing-search-input {
        max-width: 250px;
    }

    .rounded {
        border-radius: 12px !important;
    }

    .dashboard-container {
        .dashboard-statistics {
            .dashboard-statistics-item {
                .icon {
                    padding: 12px;
                    border-radius: 50%;
                    background-color: #F9F7F5;
                }

                .value {
                    font-size: 32px;
                    line-height: 48px;
                    font-weight: 700;
                    color: #1E1D1D;
                }

                .label {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    color: black;
                }
            }
        }

        .dashboard-onboarding, .dashboard-azure-global-insights {
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            color: #666564;
            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #424141;
            }

            .value {
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                color: #424141;
            }

            .apexcharts-legend {
                .apexcharts-legend-series {
                    width: 100%;
                    border-bottom: 1px solid #E4E3E2;
                    padding: 8px 0;
                }
            }
        }
    }

    .general-information{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #1E1D1D;
        .title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
        }

        .subtitle {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #8A8988;
        }

        .label {
            color: 8A8988;
            display: flex;
            align-items: center;
        }
        
        .value {
            font-weight: 600;
            display: flex;
            align-items: center;
            word-break: break-word;
        }
        
        .none {
            color: #c6c6c6;
        }
        
        p {
            margin-bottom: 0;
        
        }
    }

    .general-information-table{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: black;
        .title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: #1B1B1B;
        }

        p {
            margin-bottom: 0;
        }
    }

    .new-list-view-all {
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        color: #202126;
        cursor: pointer;
    }
    
    .new-list-item {
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #e6e6eb;
        margin-bottom: 12px;
        width: 100%;
    }
    
    .new-list-item-no-border {
        padding: 0;
        margin-bottom: 12px;
        width: 100%;
    }

    .new-list-item-border-bottom {
        padding: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        border-bottom: 1px solid #e6e6eb;
    }

    .list-border-bottom .ant-list-items {
        border-bottom: 1px solid #e6e6eb;
    }

    .new-list-item-subtitle {
        font-weight: 400;
        color: #42444a;
        }
        
    .new-list-item-subtitle-small {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #666564;
    }
    
    .new-list-item-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #424141;
    }
    
    .new-list-item-tag {
        border: 1px solid #c6c6ce;
    border-radius: 12px;
    padding: 4px 8px;
    color: #42444a;
    font-size: 12px;
    line-height: 16px;
    }

    .new-list-item-separator {
    content: '';
    border-left: 1px solid #c6c6ce;
    width: 1px;
    height: 12px;
    /* font-size: 12px;
    line-height: 12px;
    color: #C6C6CE; */
    }
    
    .new-list-item .more-icon {
    font-size: 16px;
    color: #757680;
    font-weight: 700;
    cursor: pointer;
    padding-left: 12px;
    }

    .button-actions {
        background-color: #225EE5;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        border-radius: 6px;
        cursor: pointer;
    }

    .donutchart {
        .legend {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: #666564;
        }

        .percent {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #424141;
        }

        .total {
            position: absolute;
            top: 120px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            
            .number {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                color: #424141;
            }

            .text {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: #666564;
            }
        }

    }
}

.offcanvas-active {
    .page{
        left: 50px;
        width: calc(100% - 50px);
    }

    #left-sidebar {
        z-index: 99;
        width: 50px;
    }
}

a {
    color: #000000;
}

.ant-table table{
    table-layout: fixed !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 16px 20px;
    flex-wrap: wrap;
}

.ant-list .ant-list-pagination:not(.ant-list-pagination-align-center) .ant-pagination {
    display: flex;
    flex-wrap: wrap;
}

.ant-pagination {
    position: relative;
}

.ant-pagination .ant-pagination-options {
    display: flex;
    justify-content: end;
    flex: 5;
}

.ant-pagination .ant-pagination-total-text {
    position: absolute;
    right: 100px;
    bottom: 2px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #8A8988;
    display: flex;
    align-items: center;
}

.ant-form-item-required {
    flex-direction: row-reverse;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-no-colon::after {
    display: none;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-left: 2px;
}

.ant-form-item {
    margin-bottom: 10px;
}

.ant-space .ant-space-item {
    display: flex;
    align-items: center;
}

.ant-menu{
    .ant-menu-item .ant-menu-title-content  {
        line-height: 20px;
    }

    .ant-menu-item:not(.ant-menu-item-selected), .ant-menu-submenu:not(.ant-menu-submenu-selected)  {
        font-weight: 600;

        .ant-menu-title-content, .ant-menu-title-content a{
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #8A8988;
        }
    }
} 

.ant-form-item .ant-form-item-label > label::after {
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.ant-list-item {
    border-block-end: 0 !important;
    padding: 0 !important;
}

.ant-dropdown {
    min-width: 260px !important;
}

.ant-card .ant-card-body {
    padding: 16px;
}

.ant-tag {
    font-weight: 700;
}

.ant-modal .ant-modal-footer{
    text-align: center;
}

.ant-list-item:hover {
    box-shadow: 0 0 4px #eee;
    background-color: #F7F5F3;

    .new-list-item-title{
        color: blue !important;
    }
}

.ant-checkbox .ant-checkbox-inner {
    border-color: black !important;
}

.ant-menu-light .ant-menu-item-selected {
    background-color: #F5F2F0 !important;
    color: #000000;
    font-weight: 600;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active a{
    color: #225EE5 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    border: 1px solid #225EE5 !important;
    padding: 0 12px;
    border-radius: 8px;
}

.ant-tabs .ant-tabs-tab {
    margin-bottom: 12px !important;
}

.ant-tabs .ant-tabs-ink-bar {
    display: none;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table {
    border-radius: 0 !important;
}


.ant-table {
    border-top: 1px solid #E4E3E2;
}

.ant-table-thead .ant-table-cell {
    background-color: #F7F6F4 !important;
}
.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td{
    border-bottom: 1px solid #E4E3E2 !important;
}
.ant-badge .ant-badge-count {
    height: 18px;
    align-items: center;
    font-weight: 600;
}

.ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
    color:#000000;
    font-weight: 600;
}

.ant-table-wrapper .ant-table-column-title {
    font-weight: 600;
}


@media screen and (max-width: 1200px) {
    #left-sidebar {
        left: 0;
    }
}


@media screen and (max-width: 767px) {
    .page {
        padding: 56px 0 0 0;
        width: 100%;
        left: 0;
    }
    #left-sidebar {
        left: -272px;
        z-index: 0;
        width: 272px !important;
    }

    #left-sidebar.show {
        left: 0;
        z-index: 1000;
    }

    .d-sm-none {
        display: flex !important;
    }

    .ant-pagination .ant-pagination-total-text {
        display: none;
    }
}

.notification-container {
    width: 400px;
    max-height: 500px;
    overflow: hidden auto;
}

.notification-item {
    &:hover,
    &:focus {
        background: #E8E9E9;
    }
}

.active-tag {
    border-color: #27A376;
    color: #27A376;
    background-color: #E3F6EC;
}

.yellow-tag {
    border-color: #ffe58f;
    color: #d48806;
    background-color: #fffbe6;
}

.new-hire-tag {
    border-color: #4387FF;
    color: #4387FF;
    background-color: #E0F1FF;
}

.employee-background {
    height: 144px;
    width: 100%;
    background-image: url('/src/assets/images/employee-background.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.spot-primary {
    display: flex;
    background-color: #225EE5;
    width: 6px;
    height: 20px;
    border-radius: 12px;
    border: 1px solid #225EE5;
    margin-right: 4px;
}

.phone-input-country {
    position: relative;
    display: inline-flex;
    max-height: 3rem; /* 12 * 0.25rem = 3rem */
    align-items: center;
    align-self: stretch;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border: 1px solid var(--light-300);
    ring-offset-color: var(--primary);
    transition: box-shadow 0.3s ease;
}

.phone-input-country:focus-within {
    z-index: 10;
    border-color: var(--light-400);
    color: var(--dark-100);
    outline: none;
    box-shadow: 0 0 0 2px var(--primary);
}

.phone-input-country:hover {
    background-color: var(--light-white-100);
    color: var(--dark-100);
}

.phone-input-country:disabled {
    pointer-events: none;
    opacity: 0.5;
}

.phone-input-country .ant-select-selection-item {
    align-items: center;
    display: flex;
}

.phone-input-country + #phoneNumber {
    border-radius: 0.5rem;
    border: 1px solid #b9b9c3;
    color: #1E1D1D;
    width: 100%;
    padding: 0 12px;
}

.phone-input-country + #phoneNumber::placeholder {
    padding-left: 10px;
    color: #bfbfbf
}

