#send-message {
  .sticky-search {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .message-list {
    overflow: hidden;
  }

  .message-hover:hover {
    background: #eee;
    cursor: pointer;
  }

  .message-selected {
    background-color: #F5F2F0;
    cursor: pointer;
  }

  .message-content {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .message-content-wrapper .message-content-container {
    padding: 14px 8px;
  }
}

#custom-upload {
  .ant-upload-list.ant-upload-list-text {
    background: white;
    position: absolute;
    bottom: 150px;
    width: 100%;
    left: 0;
  }
  .ant-upload-list-item.ant-upload-list-item-done {
    border-top: 1px solid var(--Neutral-100, #dcdde5) !important;
  }

  .ant-upload.ant-upload-drag {
    border: none !important;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload  {
    padding: 12px 0;
  }
}
